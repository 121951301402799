import { Link } from 'gatsby';
import Heading from '../../../../components/Heading';
import MutedText from '../../../../components/MutedText';

import { Post, Heading as HeadingClass, HeadingLink } from './Posts.module.css';

const Posts = ({ posts }) => {
  const PostLink = ({ post, ...props }) => <Link to={`/${post.slug}`} {...props} />;

  const renderPost = (post) => (
    <div className={Post} key={post.id}>
      <MutedText>
        {post.frontmatter.date} - {post.timeToRead} min read
      </MutedText>
      <Heading element="h2" className={HeadingClass}>
        <PostLink className={HeadingLink} post={post}>
          {post.frontmatter.title}
        </PostLink>
      </Heading>
      <p>{post.excerpt}</p>
      <PostLink post={post}>Read this article →</PostLink>
    </div>
  );

  return posts.map(renderPost);
};

export default Posts;
