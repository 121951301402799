import classNames from 'classnames';

import { Root } from './Heading.module.css';

const Heading = ({ element: Element, className, ...props }) => (
  <Element className={classNames(Root, className)} {...props} />
);

Heading.defaultProps = {
  element: 'h1',
};

export default Heading;
