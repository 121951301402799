import classNames from 'classnames';
import { Root } from './MutedText.module.css';

const MutedText = ({ element: Element, className, ...props }) => (
  <Element className={classNames(Root, className)} {...props} />
);

MutedText.defaultProps = {
  element: 'div',
};

export default MutedText;
