import { graphql } from 'gatsby';
import * as React from 'react';
import SEO from '../components/SEO';
import Posts from '../features/blog/components/Posts';
import SiteLayout from '../layouts/SiteLayout';

// markup
const BlogPage = ({ data }) => (
  <SiteLayout>
    <h1>Blog posts</h1>
    <SEO title="Blog posts" />
    <Posts posts={data.allMdx.nodes} />
  </SiteLayout>
);

export const query = graphql`
  query HomePageQuery {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        id
        slug
        excerpt(pruneLength: 250)
        frontmatter {
          title
          date(formatString: "YYYY MMMM Do")
        }
        timeToRead
      }
    }
  }
`;

export default BlogPage;
